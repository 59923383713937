const Footer = () => {
  return (
    <footer className="footer">
      <div className="wedding-info">
        <div className="wedding-address">
          <p>Chủ nhật, ngày 08 tháng 01 năm 2023</p>
          <p>456 Nguyễn Tri Phương, Hải Châu, Đà Nẵng</p>
        </div>
        <div className="wedding-contact">
          <p>Điện thoại: 0707361996</p>
          <p>Email: ndqlinh36@gmail.com</p>
        </div>
        <div className="wedding-protagonist">
          Quang Linh <p>&</p> Quỳnh Nhi
        </div>
      </div>
    </footer>
  );
};

export default Footer;
