const Map = () => {
  const mapSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2093.254651707768!2d108.21095977863612!3d16.050837774829095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd795eb5473cdc896!2zMTbCsDAzJzAyLjQiTiAxMDjCsDEyJzQyLjkiRQ!5e0!3m2!1svi!2s!4v1662689034876!5m2!1svi!2s';

  return (
    <div className="google-map">
      <iframe src={ mapSrc } title="Minh Chau Viet Restaurant" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
};

export default Map;
