import Landing from './app/components/Landing';
import './stylesheet/styles.scss';
function App() {
  return (
    <div className="App">
      <Landing />
    </div>
  );
}

export default App;
