import { roundTime } from '../shared/utils/common.util';

interface CountDownProps {
  days: string | number
  hours: string | number;
  minutes: string | number;
  seconds: string | number;
  completed: boolean;
}

const CountDownRenderer = ({ days, hours, minutes, seconds, completed }: CountDownProps) => {
  return (
    <>
      {
        !completed &&
          <>
            <p
              className="tagline"
              data-aos="fade-zoom-in"
              data-aos-delay="1200"
            >Sẽ kết hôn sau</p>
            <div className="countdown-timer" data-aos="fade-zoom-in" data-aos-delay="1800">
              <span>
                <p>{ roundTime(+days) }</p>
                <p>Ngày</p>
              </span>
              <span>
                <p>{ roundTime(+hours) }</p>
                <p>Giờ</p>
              </span>
              <span>
                <p>{ roundTime(+minutes) }</p>
                <p>Phút</p>
              </span>
              <span>
                <p>{ roundTime(+seconds) }</p>
                <p>Giây</p>
              </span>
            </div>
          </>
      }
    </>
  );
}

export default CountDownRenderer;
