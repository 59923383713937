import { useEffect, useRef } from 'react';

import Countdown from 'react-countdown';
import Slider from 'react-slick';
import ReactPlayer from 'react-player/lazy';
import AOS from 'aos';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { GiBigDiamondRing } from "react-icons/gi";
import { ImFacebook, ImInstagram, ImTwitter } from "react-icons/im";

import CountDownRenderer from './CountDownRenderer';
import arrowUp from '../../assets/images/white-arrow.svg';
import arrowDown from '../../assets/images/white-arrow-reverse.svg';
import { ReactComponent as Logo } from '../../assets/images/logo-white.svg';
import flower from '../../assets/images/flower.svg';
import { ReactComponent as UnderLine } from '../../assets/images/underline.svg';
import Map from './Map';
import Footer from './Footer';

const Landing = () => {
  const slider = useRef<any>(null);

  const stories = [
    {
      year: 2020,
      timeline: [
        {
          date: '8 tháng 1',
          hasSlider: false,
          hasVideo: null,
          images: [
            require('../../assets/images/stories/2020_1.JPG'),
            require('../../assets/images/stories/2020_2.JPG')
          ],
          story: {
            title: 'Lần đầu gặp nhau',
            content: 'Gặp nhau lần đầu tiên vào một buổi đi chơi với nhóm bạn chung. Lúc đó 2 đứa chẳng có ấn tượng gì với nhau cả, nhưng sau vài lần gặp gỡ đã trở nên thân thiết với nhau hơn. Trong một kì nghỉ lễ, mình trở về VN sau chuyến công tác tại JP. Tụi mình đã có những cuộc hẹn, những buổi đi chơi cùng nhau. Cũng trong thời gian này, tụi mình đã nảy sinh tình cảm với nhau.'
          }
        }
      ]
    },
    {
      year: 2021,
      timeline: [
        {
          date: null,
          hasSlider: true,
          hasVideo: null,
          images: [
            require('../../assets/images/stories/2021_1.JPG'),
            require('../../assets/images/stories/2021_2.JPG'),
            require('../../assets/images/stories/2021_3.JPEG'),
            require('../../assets/images/stories/2021_4.JPEG')
          ],
          story: {
            title: 'Kỷ niệm đáng nhớ',
            content: 'Tụi mình đã cùng nhau trải qua nhiều kỉ niệm đẹp, nhiều cảm xúc và hiểu nhau hơn. Tụi mình cũng đã từng có bao lần chia tay hụt vì những lí do hết sức vớ vẩn. Nhưng cũng chính khoảng thời gian đó đã giúp tụi mình nhận ra đối phương thực sự quan trọng như thế nào, họ chính là người có thể đi chung cả cuộc đời này.'
          }
        }
      ]
    },
    {
      year: 2022,
      timeline: [
        {
          date: '15 tháng 7',
          hasSlider: false,
          hasVideo: true,
          images: [
            require('../../assets/images/stories/2022_1.jpg'),
            require('../../assets/images/stories/2022_2.JPG')
          ],
          story: {
            title: 'Quyết định tiến đến hôn nhân',
            content: 'Trải qua thời gian 3 năm tìm hiểu nhau, có vui, có buồn, có hạnh phúc, có giận hờn, có cãi vã chỉ có một thứ duy nhất không thay đổi, đó là tình yêu mà cả 2 dành cho nhau. Tụi mình cũng đã thành công trong việc gìn giữ và vun đắp cho mảnh tình này để rồi chỉ còn vài ngày nữa thôi, hai đứa sẽ chính thức về chung một nhà.'
          }
        }
      ]
    }
  ];

  const sliderConfig = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    useTransform: false
  };

  const videoPlayerConfig = {
    file: {
      attributes: {
        poster: require('../../assets/images/video-thumbnail.jpg'),
        preload: 'metadata'
      }
    }
  };

  const onNext = () => {
    if (slider?.current) {
      slider.current.slickNext();
    }
  };

  const onPrev = () => {
    if (slider?.current) {
      slider.current.slickPrev();
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      disable: 'mobile'
    });
  }, []);

  return (
    <div className="landing-page">
      <section className="section-landscape">
        <video
          autoPlay
          playsInline
          muted
          loop
          className="video-landscape"
          poster={ require("../../assets/images/invitation-background.jpeg") }>
          <source src={ require("../../assets/videos/teaser-landscape.mp4") } type="video/mp4"/>
        </video>
        <div className="section-content">
          <div className="container">
            <div className="wedding-main">
              <img
                className="svg-arrow"
                src={ arrowUp }
                alt="logo"
                data-aos="fade-down"
              />
              <h1
                className="title"
                data-aos="fade-zoom-in"
                data-aos-delay="500"
                data-aos-offset="0"
              >
                Quang Linh <span>&</span> Quỳnh Nhi
              </h1>
              <img
                className="svg-arrow"
                src={ arrowDown }
                alt="logo"
                data-aos="fade-up"
                data-aos-offset="0"
              />
            </div>
            <Countdown
              date={ new Date('8 january 2023 07:00:00') }
              renderer={ CountDownRenderer }
            />
          </div>
        </div>
      </section>
      <section className="section-introduction">
        <div className="container">
          <div className="user-card" data-aos="fade-right" data-aos-offset="300">
            <div className="flower-icon">
              <img src={ flower } alt="flower" />
            </div>
            <div className="user-image">
              <img src={ require("../../assets/images/groom_1.jpg") } alt="avatar" />
            </div>
            <div className="user-info">
              <h3 className="user-name">Quang Linh</h3>
              <p className="user-role">Chú rể</p>
              <p className="user-desc">
                Là một chàng lập trình viên cung Song Tử, trầm tính, ít nói nhưng bên trong lại rất ấm áp. Thích ăn uống, bơi lội và đi du lịch.
              </p>
              <ul className="user-socials">
                <li className="user-social-item">
                  <a href="https://www.facebook.com/linhnguyen.3696/" target="_blank" rel="noreferrer">
                    <ImFacebook />
                  </a>
                </li>
                <li className="user-social-item">
                  <a href="https://www.instagram.com/ndqlinh36/" target="_blank" rel="noreferrer">
                    <ImInstagram />
                  </a>
                </li>
                <li className="user-social-item">
                  <a href="https://twitter.com/linhnguyen3696/" target="_blank" rel="noreferrer">
                    <ImTwitter />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="user-card user-card-reverse" data-aos="fade-left" data-aos-offset="300">
            <div className="flower-icon">
              <img src={ flower } alt="flower" />
            </div>
            <div className="user-image">
              <img src={ require("../../assets/images/bride_1.jpg") } alt="avatar" />
            </div>
            <div className="user-info">
              <h3 className="user-name">Quỳnh Nhi</h3>
              <p className="user-role">Cô dâu</p>
              <p className="user-desc">
                Là cô giao dịch viên mà tính tình nóng nảy, khó chiều nhưng lại hay cười, thích mọi thứ lấp lánh màu hồng, thích du lịch và khám phá food tour.
              </p>
              <ul className="user-socials">
                <li className="user-social-item">
                  <a href="https://www.facebook.com/nhinhiiquynh/" target="_blank" rel="noreferrer">
                    <ImFacebook />
                  </a>
                </li>
                <li className="user-social-item">
                  <a href="https://www.instagram.com/quynhnhinhii/" target="_blank" rel="noreferrer">
                    <ImInstagram />
                  </a>
                </li>
                <li className="user-social-item">
                  <a href="https://twitter.com/nlqnhii/" target="_blank" rel="noreferrer">
                    <ImTwitter />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section-story">
        <div className="section-title parallax">
          <div className="section-divider-top" />
          <h2 className="title">
            Our love story
            <div className="underline-icon">
              <UnderLine />
            </div>
          </h2>
          <div className="section-divider-bottom" />
        </div>
        <div className="section-content">
          <div className="container">
            <ul className="stories">
              {
                stories.map((story, ind) => (
                  <li key={ ind } className="story-item">
                    <div className="story-year">
                      <div className="double-border">
                        { story.year }
                      </div>
                    </div>
                    <ul className="story-timeline">
                      {
                        story.timeline.map((item, ind) => (
                          <li
                            key={ ind }
                            className={ `timeline-item ${ item.hasSlider && 'timeline-flex' } ${ item.hasVideo && 'timeline-video' }` }
                          >
                            {
                              item.hasVideo &&
                              <div className="player-container">
                                <ReactPlayer
                                  url={[
                                    { src: require('../../assets/videos/teaser.mp4'), type: 'video/mp4' },
                                    { src: require('../../assets/videos/teaser.webm'), type: 'video/webm' }
                                  ]}
                                  width="100%"
                                  height="100%"
                                  playsinline={ true }
                                  controls={ true }
                                  config={ videoPlayerConfig }
                                />
                              </div>
                            }
                            {
                              !!item.date &&
                              <div className="timeline-date">
                                <div className="double-border">
                                  { item.date }
                                </div>
                              </div>
                            }
                            {
                              item.hasSlider ?
                                <div className="timeline-slider">
                                  <Slider ref={ slider } {...sliderConfig}>
                                    {
                                      item.images.map((url, ind) => (
                                        <div key={ ind } className="slick-image">
                                          <img src={ url } alt="story_image" />
                                        </div>
                                      ))
                                    }
                                  </Slider>
                                  <div className="timeline-slider-actions">
                                    <button className="timeline-slider-button timeline-slider-prev" onClick={ onPrev }>
                                      <BiChevronLeft />
                                    </button>
                                    <button className="timeline-slider-button timeline-slider-next" onClick={ onNext }>
                                      <BiChevronRight />
                                    </button>
                                  </div>
                                </div> :
                                <ul className="timeline-images">
                                  {
                                    item.images.map((url, ind) => (
                                      <li key={ ind } className="image-item">
                                        <img src={ url } alt="story_image" />
                                      </li>
                                    ))
                                  }
                                </ul>
                            }
                            <div className="timeline-story">
                              <div className="double-border">
                                <h4 className="title">
                                  { item.story.title }
                                </h4>
                                <div className="underline-icon">
                                  <UnderLine />
                                </div>
                                <p className="content"> { item.story.content }</p>
                              </div>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                ))
              }
              <li className="story-item story-final">
                <p className="story-icon">
                  <GiBigDiamondRing />
                </p>
                <div className="content">
                  <h4>
                    This is where our
                  </h4>
                  <h2>
                    Forever <br/> Begins!
                  </h2>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="section-invitation parallax">
        <div className="section-divider-top" />
        <div className="section-divider-bottom" />
        <div className="container">
          <div className="section-title">
            <h2 className="title">
              Invitation
              <div className="underline-icon">
                <UnderLine />
              </div>
            </h2>
          </div>
          <div className="section-content">
            <div className="double-border">
              <div className="wedding-invitation row row-no-gutter">
                <div className="col-12 col-lg-6 left-side">
                  <div className="wedding-invitation-cover">
                    <h2>Save <p>the</p> Date</h2>
                  </div>
                </div>
                <div className="col-12 col-lg-6 right-side">
                  <div className="wedding-invitation-content">
                    <div className="logo">
                      <Logo />
                    </div>
                    <p className="tagline">
                      Trân trọng kính mời Quý khách đến dự tiệc chung vui cùng gia đình chúng tôi
                    </p>
                    <div className="wedding-datetime">
                      <p className="date">Ngày 08 tháng 01 năm 2023</p>
                      <p className="time">vào lúc 11:00</p>
                    </div>
                    <div className="wedding-place">
                      <p>Tại trung tâm hội nghị tiệc cưới</p>
                      <p className="restaurant-name">Minh Châu Việt</p>
                      <p>Sảnh B - Tầng 2</p>
                      <p>456 Nguyễn Tri Phương, Hải Châu, Đà Nẵng</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-location">
        <div className="section-title">
          <h2 className="title">
            Location
            <div className="underline-icon">
              <UnderLine />
            </div>
          </h2>
        </div>
        <div className="section-content">
          <Map />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Landing;
